import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Button,
  Modal,
  Divider,
  Form,
  Input,
  Space,
  DatePicker,
  Select,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {getConfigIndex, getWebsite} from '../../../services/api';
import { debounce } from 'lodash';

const telegramIdRegExp = /^(?:https?:\/\/t\.me\/)?([A-Za-z0-9_]+)$/;
const linkSuggestRegExp =
  /^(?:(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?)|([a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?)|(?:https?:\/\/)?(?:www\.)?(\d{1,3}\.){3}\d{1,3})(\/[^\s]*)?$/;

const { Option } = Select;

export default function ModalFunction({
  dataEdit,
  isModalFunction,
  setIsModalFunction,
  onFinish,
  title,
  form,
}) {
  const { id } = useParams();
  const [listWebsite, setListWebsite] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [search, setSearch] = useState('');
  const [configIndex, setConfigIndex] = useState([]);

  useEffect(() => {
    if (isModalFunction) {
      setIsLoading(true);
      getConfigIndexForUser()
      fetchDataWebsite(page).finally(() => {
        setIsLoading(false);
      })

      if (dataEdit && !isLoading) {
        const websiteId  =  id ?? dataEdit?.websiteId?._id
        const isExitInList = listWebsite.find((item) => item._id === websiteId)
        if (!isExitInList) {
          const dataSearch = dataEdit?.websiteId?.domain
          getWebsite({ limit: 20, page, search: dataSearch }).then((res) => {
            if (res?.data?.items?.length > 0) {
              const formatData = res?.data?.items.map((item) => item?.website || item);
              const newListWebsite = [...listWebsite, ...formatData];
              setListWebsite(newListWebsite);

              form.setFieldsValue({
                name: dataEdit.name,
                linkSuggest: dataEdit.linkSuggest,
                guaranteed: dataEdit.guaranteed ? dayjs(dataEdit.guaranteed) : null,
                websiteId: websiteId,
                timer: dataEdit.timer,
                configIndexId: dataEdit?.configIndexId?._id,
              });
            }
          })
        } else {
          form.setFieldsValue({
            name: dataEdit.name,
            linkSuggest: dataEdit.linkSuggest,
            guaranteed: dataEdit.guaranteed ? dayjs(dataEdit.guaranteed) : null,
            websiteId: websiteId,
            timer: dataEdit.timer,
            configIndexId: dataEdit?.configIndexId?._id,
          });
        }
      }

      if (!dataEdit && !isLoading) {
        form.setFieldsValue({
          timer: 30,
        });
      }
    }
  }, [isModalFunction]);

  const debouncedFetchData = useCallback(
    debounce((page) => {
      fetchDataWebsite(page)
    }, 500),
    [search]);

  useEffect(() => {
    debouncedFetchData(page);

    return () => {
      debouncedFetchData.cancel();
    };
  }, [search, debouncedFetchData]);

  const handleCancel = () => {
    setIsModalFunction(false);
    form.resetFields();
  };

  const fetchDataWebsite = async (page, isLoadMore = false) => {
    const res = await getWebsite({ limit: 50, page, search });
    if (res?.data?.items?.length > 0) {

      // calculate max page
      const total = +res?.data?.pagination?.total || 0;
      const limit = +res?.data?.pagination?.limit || 0;
      const maxPage = Math.ceil(total / limit);
      setMaxPage(maxPage);

      const formatData = res?.data?.items.map((item) => item?.website || item);
      if (isLoadMore) {
        setListWebsite((prev) => {
          const newItems = formatData.filter((item) => !prev.some((prevItem) => prevItem._id === item._id));
          return [...prev, ...newItems];
        });
        setIsLoadMore(false)
      } else {
        const newItems = [...listWebsite, ...formatData]
        const uniqueItems = newItems.filter((item, index) => newItems.findIndex((t) => t._id === item._id) === index)
        setListWebsite(uniqueItems);
      }
    }
  };

  // const options = [
  //   {
  //     value: 0,
  //     label: 'Không giới hạn',
  //   },
  // ];
  // for (let i = 1; i <= 30; i++) {
  //   options.push({
  //     value: i,
  //     label: i + 's',
  //   });
  // }

  const options = Array.from({ length: 30 }, (_, i) => ({
    value: i + 1,
    label: i + 1 + 's',
  }));
  options.push({
    value: 0,
    label: 'Không giới hạn',
  });

  const onScroll = async (event) => {
    const target = event.target
    if (!isLoadMore && target.scrollTop + target.offsetHeight === target.scrollHeight) {

      // check if page is max page
      if (page >= maxPage) return

      setIsLoadMore(true)
      target.scrollTo(0, target.scrollHeight)
      const newPage = page + 1
      setPage(newPage)
      await fetchDataWebsite(newPage, true)
    }
  }

  const onSearch = async (value) => {
    setSearch(value);
    setPage(1);
  }

  const getConfigIndexForUser = async () => {
    try {
      const res = await getConfigIndex()
      if (res?.data?.items?.length > 0) {
        const formatData = res?.data?.items.map((item) => {
          return {
            name: item.name,
            code: item.code,
            _id: item._id
          }
        });
        setConfigIndex(formatData);
      }
    } catch (e) {
    }
  }

  const handleChangeConfigType = (value) => {
  }

  return (
    <div>
      <Modal
        title={title}
        open={isModalFunction}
        onCancel={handleCancel}
        footer={false}
      >
        <Divider/>
        {isLoading
          ?
          <div
            style={{
              minHeight: 300,
            }}
            className={'flex items-center justify-center'}
          >
            <svg
              aria-hidden="true"
              className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
          :
          <>
            <Form
              name='basic'
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 'auto',
              }}
              style={{
                maxWidth: 500,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete='off'
              form={form}
            >
              {!id && (
                <Form.Item
                  label='Chọn domain'
                  name='websiteId'
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn 1 domain!',
                    },
                  ]}
                >
                  <Select
                    placeholder='Chọn domain'
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onPopupScroll={onScroll}
                    loading={isLoadMore}
                    onSearch={onSearch}
                  >
                    {listWebsite?.length > 0 &&
                      listWebsite?.map((item) => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {item.domain}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                label='Mã đơn hàng'
                name='name'
                rules={[
                  {
                    required: true,
                    message: 'Nhập đề xuất đi nào!',
                  },
                ]}
              >
                <Input allowClear/>
              </Form.Item>

              <Form.Item
                label='Link đề xuất'
                name='linkSuggest'
                rules={[
                  {
                    required: true,
                    message: 'Nhập link đề xuất!',
                  },
                  {
                    pattern: linkSuggestRegExp,
                    message: 'Link đề xuất không hợp lệ!',
                  },
                ]}
              >
                <Input allowClear/>
              </Form.Item>

              <Form.Item
                label='Bảo hành'
                name='guaranteed'
                rules={[
                  {
                    required: true,
                    message: 'Chọn thời gian bảo hành!',
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) => {
                    let customDate = moment().format('YYYY-MM-DD');
                    return current && current < moment(customDate, 'YYYY-MM-DD');
                  }}
                  placeholder='Chọn ngày kết thúc bảo hành'
                  className='w-full'
                  format='DD/MM/YYYY HH:mm'
                  showNow={false}
                  footer={true}
                  showTime={{use24Hours: true}}
                />
              </Form.Item>

              <Form.Item
                label='Thời gian load link'
                name='timer'
                rules={[
                  {
                    required: 'true',
                    message: 'Vui lòng chọn thời gian!',
                    // validator: validateNumberRange,
                  },
                ]}
              >
                {/* <Input placeholder='Chọn thời gian' maxLength={2} allowClear /> */}
                <Select placeholder='Chọn thời gian' options={options} allowClear/>
              </Form.Item>

              <Form.Item
                label='Loại index'
                extra={
                  <>
                    <i>Cài đặt index này sẽ được sử dụng cho việc index link đề xuất</i>
                  </>
                }
                name='configIndexId'
                rules={[
                  {
                    required: false,
                    message: 'Loại index',
                  },
                ]}
              >
                <Select placeholder='Chọn loại index' onChange={handleChangeConfigType}>
                  {configIndex.map((item) => (
                    <Select.Option key={item._id} value={item._id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <div className='text-right mt-8'>
                <Space>
                  <Button type='primary' danger onClick={handleCancel}>
                    Hủy
                  </Button>
                  <Button htmlType='submit' type='primary' className='btn-modal'>
                    Xác nhận
                  </Button>
                </Space>
              </div>
            </Form>
          </>
        }
      </Modal>
    </div>
  );
}
