import {
  Form,
  Popconfirm,
  message,
  Tag,
  Space,
  Button,
  Flex,
  Select,
  Input, Avatar,
} from 'antd';
import React, {useEffect, useState} from 'react';
import {
  deleteUser,
  getAccountInfo,
  getUserById,
  getUsers,
  postCreteNewUser,
  putEditUser,
  blockUser,
  unBlockUser,
} from '../../../services/api';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined, LockOutlined,
} from '@ant-design/icons';
import DataTable from '../../common/DataTable';
import moment from 'moment';
import {BtnTele} from '../../common/BtnTele';
import ViewUser from './ViewUser';
import ModalUser from './ModalUser';
import {debounce, isValidUrl} from '../../common/function';
import {handleExport} from '../../common/ExportExcel';
import {useDispatch} from 'react-redux';
import {getIdValue} from '../../../redux/userReducer';

const headerRowFile = [
  'STT',
  'Tên',
  'Email',
  'Phân quyền',
  'Leader',
  'Telegram',
  'Ngày tạo',
  'Trạng thái',
];

export default function ManageUser() {
  document.title = 'Quản lý người dùng';
  const dispatch = useDispatch();
  const [listUsers, setListUsers] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    search: '',
    role: '',
    status: '',
    leaderId: '',
  });
  const [total, setTotal] = useState(1);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [idEdit, setIdEdit] = useState('');
  const [title, setTitle] = useState('');
  const [role, setRole] = useState('');
  const [userId, setUserId] = useState('');
  const [leaders, setLeaders] = useState([]);

  const [form] = Form.useForm();
  const {Option} = Select;

  useEffect(() => {
    fetchListUsers();
  }, [params]);

  useEffect(() => {
    getRoleId();
    getLeader()
  }, []);

  // kiểm tra user phải leader ko
  const getRoleId = async () => {
    let res = await getAccountInfo();
    setUserId(res?.data?.roleId?.name);
  };

  // get user
  const fetchListUsers = async () => {
    setLoading(true);
    try {
      const res = await getUsers(params);
      setListUsers(res?.data?.items);
      setTotal(res?.data?.pagination?.total);
    } catch (error) {
      message.error(error?.message);
    }
    setLoading(false);
  };

  const getLeader = async () => {
    const res = await getUsers({role: 'leader', limit: 99999});
    const mapData = res?.data?.items?.map(item => {
      return {
        leaderId: item._id,
        name: item.name,
        email: item.email,
      }
    })
    setLeaders(mapData);
  }

  const dataSource = listUsers.map((item) => {
      return {
        key: item?._id,
        name: item?.name,
        email: item?.email,
        roleId: item?.roleId?.description,
        leaderId: item?.leaderId?.name,
        telegramId: item?.telegramId,
        created_at: moment(new Date(item?.created_at))?.format(
          'DD/MM/YYYY HH:mm'
        ),
        status: item?.status
      };
    });

  const handleChange = (role) => {
    setParams({...params, page: 1, role: role});
  };

  const handleChangeStatus = (status) => {
    setParams({...params, page: 1, status: status});
  };

  const onSearch = (e) => {
    setParams({...params, page: 1, search: e.target.value});
  };

  const debouncedFunction = debounce(onSearch, 800);

  const openModal = async (id, title) => {
    try {
      const res = await getUserById(id);
      setRole(res?.data?.roleId?.name);
      if (res.status === true) {
        const tele = isValidUrl(res?.data?.telegramId)
          ? new URL(res?.data?.telegramId).pathname.split('/').pop()
          : res?.data?.telegramId;
        form.setFieldsValue({
          name: res?.data?.name,
          email: res?.data?.email,
          role: res?.data?.roleId?.name,
          leaderId: res?.data?.leaderId?._id,
          telegramId: tele,
        });
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      message.error(error.message);
    }
    setIdEdit(id);
    setIsEdit(true);
    setShow(true);
    setTitle(title);
  };

  const submitModal = async (values) => {
    try {
      const tele = values?.telegramId?.startsWith('https://t.me/')
        ? values?.telegramId
        : values?.telegramId === '' || values?.telegramId === undefined
          ? ''
          : 'https://t.me/' + values?.telegramId;

      const params = {
        name: values.name,
        email: values.email,
        password: values.password,
        role: values.role,
        leaderId: values.leaderId,
        telegramId: tele,
      };

      let res;
      if (isEdit === false) {
        res = await postCreteNewUser(params);
      } else {
        res = await putEditUser(idEdit, {
          name: values.name,
          role: values.role,
          password: values?.password,
          leaderId: values?.leaderId?.value ?? values?.leaderId,
          telegramId: tele,
        });
      }
      if (res?.status === true) {
        message.success(isEdit ? 'Sửa thành công!' : 'Thêm mới thành công!');
        fetchListUsers();
        handleCloseModal();
      } else {
        throw new Error(
          res?.message || (isEdit ? 'Sửa thất bại!' : 'Thêm mới thất bại!')
        );
      }
    } catch (error) {
      message.error(error.message || 'Lỗi máy chủ!');
    }
  };

  const handleCloseModal = () => {
    form.resetFields();
    setShow(false);
    setIsEdit(false);
    setRole('');
  };

  // change page
  const paginate = (pageNumber, pageSize) => {
    setParams((prev) => ({
      ...prev,
      page: pageNumber,
      limit: pageSize,
    }));
  };

  const handleDeleteUser = async (data) => {
    let res = await deleteUser(data.key);
    if (res.status === true) {
      message.success('Xóa thành công!');
    }
    fetchListUsers();
  };

  const handleUpdateStatus = async (status, data) => {
    if (!status || !data) return
    if (status === 'block') {
      blockUser(data.key).then((res) => {
        if (res.status === true) {
          message.success('Khoá user thành công!');
          fetchListUsers();
        } else {
          message.error('Khoá user thất bại!');
        }
      }).catch((error) => {
        message.error('Lỗi máy chủ!');
      })
    }
    if (status === 'unlock') {
      unBlockUser(data.key).then((res) => {
        if (res.status === true) {
          message.success('Mở khoá user thành công!');
          fetchListUsers();
        } else {
          message.error('Mở khoá user thất bại!');
        }
      }).catch((error) => {
        message.error('Lỗi máy chủ!');
      })
    }
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      render: (_, text, index) => (params.page - 1) * params.limit + index + 1,
      width: 60,
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      // render: (text) => text.charAt(0).toUpperCase() + text.slice(1),
      render: (text) => {
        return text ?
          <div className="flex items-center">
            <Avatar style={{backgroundColor: '#f0f5ff', color: '#1d39c4'}}>{text.charAt(0).toUpperCase()}</Avatar>
            <span className="text-[#1d39c4] ml-1">{text.charAt(0).toUpperCase() + text.slice(1)}</span>
          </div>
          : '';
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phân quyền',
      dataIndex: 'roleId',
      render: (text, _) => {
        const role = _?.email === 'superadmin@okvip.com' ? 'Super Admin' : text
        const color = role === 'Super Admin' ? 'blue' : role === 'User' ? '' : role === 'Leader' ? 'gold' : 'red'
        return <>
          <Tag color={color}>
            {role}
          </Tag>
        </>
      },
    },
    {
      title: 'Leader',
      dataIndex: 'leaderId',
      render: (_, {leaderId}) => {
        return leaderId ?
          <div className="flex items-center">
            <Avatar style={{backgroundColor: '#fde3cf', color: '#f56a00'}}>{leaderId.charAt(0)}</Avatar>
            <span className="text-[#f56a00] ml-1">{leaderId}</span>
          </div>
          : '';
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (_, {status}) => {
        return (
          <Tag color={status === 'active' ? 'green' : 'red'} key={status}>
            {status === 'active' ? status?.toUpperCase() : 'BLOCK'}
          </Tag>
        );
      },
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: 200,
      fixed: 'right',
      render: (text, data, index) => (
        <Space size='middle' className='gap-2 items-center '>
          <button
            onClick={() => dispatch(getIdValue(data.key))}
            className='bg-blue-600 action-button'
          >
            <EyeOutlined/>
          </button>
          <button
            onClick={() => openModal(data.key, 'Sửa Tài Khoản Nhân Viên')}
            className='bg-[#fd9900] action-button'
          >
            <EditOutlined/>
          </button>
          <Popconfirm
            cancelButtonProps={{className: 'bg-red-500 text-white btn-del'}}
            okButtonProps={{
              className: 'bg-blue-600 text-white',
            }}
            title='Xóa User'
            description='Bạn muốn xóa user này?'
            onConfirm={() => handleDeleteUser(data)}
            okText='Yes'
            cancelText='No'
            okType=''
          >
            <button className='bg-red-600 action-button'>
              <DeleteOutlined/>
            </button>
          </Popconfirm>
          <Popconfirm
            cancelButtonProps={{className: 'bg-red-500 text-white btn-del'}}
            okButtonProps={{className: 'bg-blue-600 text-white',}}
            title={data?.status === 'active' ? 'Khoá user' : 'Mở khoá user'}
            description={data?.status === 'active' ? 'Bạn muốn khoá user này?' : 'Bạn muốn mở khoá user này?'}
            onConfirm={() => handleUpdateStatus(data?.status === 'active' ? 'block' : 'unlock', data)}
            okText='Yes'
            cancelText='No'
            okType=''
          >
            <button className={data?.status === 'active' ? 'bg-gray-500 action-button' : 'bg-green-500 action-button'}>
              {data?.status === 'active' ? (
                <LockOutlined/>
              ) : (
                <span role="img" aria-label="edit" className="anticon anticon-edit">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="64 64 896 896" focusable="false" data-icon="delete"
                     width="1em" height="1em" fill="currentColor">
                  <path fill="currentColor"
                        d="M832 464H332V240c0-30.9 25.1-56 56-56h248c30.9 0 56 25.1 56 56v68c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-68c0-70.7-57.3-128-128-128H388c-70.7 0-128 57.3-128 128v224h-68c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V496c0-17.7-14.3-32-32-32M540 701v53c0 4.4-3.6 8-8 8h-40c-4.4 0-8-3.6-8-8v-53a48.01 48.01 0 1 1 56 0"/>
                </svg>
              </span>
              )}
            </button>
          </Popconfirm>
          {data.telegramId && <BtnTele tele={data.telegramId}/>}
        </Space>
      ),
    },
  ];

  const handleRefresh = () => {
    fetchListUsers();
  };

  const changeRole = (value) => {
    setRole(value);
  };

  const handleChangeLeader = (value) => {
    setParams({...params, page: 1, leaderId: value});
  }

  return (
    <>
      <Flex gap={'small'} wrap='wrap' className='mt-6'>
        <Space.Compact className='mt-[-20px] mb-[20px] w-[300px]'>
          <Input
            addonBefore={<SearchOutlined/>}
            placeholder='Tìm kiếm tên và email...'
            allowClear
            onChange={debouncedFunction}
          />
        </Space.Compact>
        {userId === 'leader' ? null : (
          <>
            <Select
              className='w-[240px] mb-6 add-user-btn'
              onChange={handleChange}
              placeholder='Lọc theo role'
            >
              <Option value=''>Tất cả role</Option>
              <Option value='admin'>Admin</Option>
              <Option value='leader'>Leader</Option>
              <Option value='user'>User</Option>
            </Select>
          </>
        )}

        {userId === 'user' ? null : (
          <Select
            className='w-[240px] mb-6 add-user-btn'
            onChange={handleChangeStatus}
            placeholder='Lọc theo trạng thái'
          >
            <Option value=''>Tất cả trạng thái</Option>
            <Option value='active'>Active</Option>
            <Option value='inactive'>Block</Option>
          </Select>
        )}

        {userId === 'admin' ?
          <Select
            className='w-[240px] mb-6 add-user-btn'
            onChange={handleChangeLeader}
            placeholder='Lọc theo leader'
          >
            <Option value=''>Tất cả leader</Option>
            {leaders?.map((item) => (
              <Option key={item.leaderId} value={item.leaderId}>
                {item.name} | {item.email}
              </Option>
            ))}
          </Select>
          : null
        }
      </Flex>

      <Flex gap={'small'} wrap='wrap' className='mt-6'>
        <Button
          type='primary'
          className='add-btn'
          style={{marginRight: '6px'}}
          onClick={() => {
            setShow(true);
            setTitle('Thêm Nhân Viên Mới');
          }}
        >
          Thêm nhân viên
        </Button>
        <Button
          onClick={() => handleExport(listUsers, headerRowFile, 'user')}
          className='btn-excel flex items-center'
          style={{marginRight: '6px'}}
          disabled={listUsers?.length === 0}
        >
          Xuất Excel
        </Button>
        <Button
          onClick={handleRefresh}
          type='primary'
          className='add-reset flex items-center bg-slate-600 text-white'
          disabled={loading}
        >
          Làm mới
        </Button>
      </Flex>

      <DataTable
        columns={columns}
        listData={dataSource}
        pageSize={params.limit}
        indexPage={params.page}
        totalPage={total}
        onPageChange={paginate}
        loading={loading}
        scroll={{x: 1500}}
      />

      <ViewUser title={title}/>

      <ModalUser
        form={form}
        show={show}
        handleCloseModal={handleCloseModal}
        submitModal={submitModal}
        title={title}
        isEdit={isEdit}
        changeRole={changeRole}
        role={role}
        userId={userId}
      />
    </>
  );
}
