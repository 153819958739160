import { createSlice } from '@reduxjs/toolkit';

export const filterLinkSlice = createSlice({
  name: 'filterLinkSlice',
  initialState: {
    isFollow: '',
    isIndex: '',
    indexed: '',
    status: '',
    leadId: '',
    resultLinkExcel: {},
    idLink: '',
  },
  reducers: {
    setFilterValue: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    getLeaderId: (state, action) => {
      state.leadId = action.payload;
    },
    listLinkExcel: (state, action) => {
      state.resultLinkExcel = action.payload;
    },
    getIdLink: (state, action) => {
      state.idLink = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFilterValue, getLeaderId, listLinkExcel, getIdLink } =
  filterLinkSlice.actions;

export default filterLinkSlice.reducer;
