import React, {useEffect, useState} from 'react';
import {
  deleteTele,
  getAccountInfo,
  getTele,
  getTeleById,
  getUsers,
  postCreteTele,
  putUpdateTele,
} from '../../../services/api';
import DataTable from '../../common/DataTable';
import {Form, Popconfirm, Skeleton, Space, Tag, message, Avatar, Button, Flex, Input} from 'antd';
import moment from 'moment';
import {EditOutlined, DeleteOutlined, SearchOutlined} from '@ant-design/icons';
import ModalTele from './ModalTele';
import HeaderComponent from '../../common/HeaderComponent';

export default function Telegram() {
  const [dataTele, setDataTele] = useState([]);
  const [listLead, setListLead] = useState([]);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    limit: 10,
    totalPage: 1,
  });
  const [idEdit, setIdEdit] = useState();
  const [getIdLeader, setGetIdLeader] = useState();
  const [userId, setUserId] = useState();
  const [getLeaderName, setGetLeaderName] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    fetchTele();
  }, [pagination.currentPage, pagination.limit, search]);

  useEffect(() => {
    fetchListLeader();
    getRoleId();
  }, []);

  // kiểm tra user phải leader ko
  const getRoleId = async () => {
    let res = await getAccountInfo();
    setGetLeaderName(res?.data?.name);
    setUserId(res?.data?.roleId?.name);
  };

  const fetchTele = async () => {
    setLoading(true);
    const res = await getTele({
      page: pagination.currentPage,
      limit: pagination.limit,
      search
    });
    setPagination((pre) => ({
      ...pre,
      totalPage: res?.data?.pagination?.total,
    }));
    if (res?.status) {
      setDataTele(res.data.items);
    }
    setLoading(false);
  };

  const dataSource = dataTele?.map((item) => {
    const leaderName = listLead
      ?.filter((data) => data._id === item.leaderId)
      ?.map((foundData) => foundData.name);
    return {
      key: item._id,
      groupName: item.groupName,
      groupId: item.groupId,
      leaderId: item.leaderId ? item?.leaderId?.name : '',
      created_at: moment(item.created_at).format('DD/MM/YYYY HH:mm'),
      updated_at: moment(item.updated_at).format('DD/MM/YYYY HH:mm'),
    };
  });

  const columns = [
    {
      title: 'Tên nhóm',
      dataIndex: 'groupName',
    },
    {
      title: 'ID nhóm',
      dataIndex: 'groupId',
    },
    {
      title: 'Leader',
      dataIndex: 'leaderId',
      render: (leaderId) => {
        return leaderId ?
          <div className="flex items-center">
            <Avatar style={{backgroundColor: '#fde3cf', color: '#f56a00'}}>{leaderId.charAt(0)}</Avatar>
            <span className="text-[#f56a00] ml-1">{leaderId}</span>
          </div>
          : '';
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'updated_at',
    },
    {
      title: 'Thao tác',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, data, index) => (
        <Space size='middle' className='gap-2 items-center '>
          <button
            onClick={() => openModal(data.key)}
            className='bg-[#fd9900] action-button'
          >
            <EditOutlined/>
          </button>
          <Popconfirm
            cancelButtonProps={{className: 'bg-red-500 text-white btn-del'}}
            okButtonProps={{
              className: 'bg-blue-600 text-white',
            }}
            title='Xóa Nhóm Telegram'
            description='Bạn muốn xóa nhóm này?'
            onConfirm={() => handleDelete(data)}
            okText='Yes'
            cancelText='No'
            okType=''
          >
            <button className='bg-red-600 action-button'>
              <DeleteOutlined/>
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // fetch list leader
  const fetchListLeader = async () => {
    const res = await getUsers({role: 'leader', limit: 0});
    setListLead(res?.data?.items);
  };

  // create & update tele gr
  const submitModal = async (values) => {
    if (edit === true) {
      const res = await putUpdateTele(idEdit, {
        ...values,
        leaderId: getIdLeader,
      });
      if (res?.status) {
        message.success('Sửa thành công!');
      } else {
        message.error('Sửa thất bại!');
      }
    } else {
      const res = await postCreteTele({
        groupName: values.groupName,
        groupId: values.groupId,
        leaderId: values.leaderId,
      });
      if (res?.status) {
        message.success('Tạo thành công!');
      } else {
        message.error(res.message);
      }
    }
    fetchTele();
    handleCloseModal();
  };

  // delete gr tele
  const handleDelete = async (data) => {
    const res = await deleteTele(data.key);
    if (res?.status) {
      message.success('Xóa thành công!');
    }
    fetchTele();
  };

  const openModal = async (id) => {
    if (id) {
      setIdEdit(id);
      const res = await getTeleById(id);
      const leaderName = listLead
        ?.filter((data) => data._id === res.data.leaderId)
        ?.map((foundData) => foundData.name);
      setEdit(true);
      setGetIdLeader(res.data.leaderId);
      form.setFieldsValue({
        groupName: res.data.groupName,
        groupId: res.data.groupId,
        leaderId: leaderName,
      });
    }
    setShow(true);
  };

  const handleCloseModal = () => {
    // setSearch("");
    setShow(false);
    setEdit(false);
    form.resetFields();
  };

  const handleRefresh = () => {
    fetchTele();
    // setSearch("");
  };

  const paginate = (currentPage, limit) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: currentPage,
      limit: limit,
    }));
  };

  // select role & leaderID đề submit edit success
  const selectValue = (value, option) => {
    setGetIdLeader(option?.key);
  };

  return (
    <>
      <Flex gap={'small'} className='mt-6'>
        <Space.Compact style={{width: 300, marginTop: "-20px"}}>
          <Input
            addonBefore={<SearchOutlined/>}
            placeholder="Tìm kiếm nhóm Tele..."
            onChange={(e) => setSearch(e.target.value)}
            allowClear
            value={search}
          />
        </Space.Compact>

        <Button type='primary' className='add-btn' onClick={() => openModal()}>
          Thêm Nhóm Telegram
        </Button>
        <Button
          onClick={handleRefresh}
          className='add-reset flex items-center bg-slate-600 text-white'
        >
          Reload
        </Button>
      </Flex>
      {loading ? (
        <Skeleton/>
      ) : (
        <DataTable
          columns={columns}
          listData={dataSource}
          pageSize={pagination.limit}
          indexPage={pagination.currentPage}
          totalPage={pagination.totalPage}
          scroll={{x: 1500}}
          onPageChange={paginate}
        />
      )}

      {/* modal tele */}
      <ModalTele
        title={!edit ? 'Thêm Nhóm Telegram' : 'Sửa Nhóm Telegram'}
        show={show}
        handleCloseModal={handleCloseModal}
        form={form}
        submitModal={submitModal}
        listLead={listLead}
        selectValue={selectValue}
        getIdLeader={getIdLeader}
        userId={userId}
      />
    </>
  );
}
