import React, { useState } from 'react';
import { Upload, Button, message, Space } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import ExcelJS from 'exceljs';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { listLinkExcel } from '../../redux/reducer';

const style = {
  color: '#4096ff',
  borderColor: '#4096ff',
  transition: 'color 0.3s',
};

const ImportExcel = (props) => {
  const { suggestId } = props;
  const [excelData, setExcelData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hovered, setHovered] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (excelData !== null) {
      setExcelData(null);
    }
  }, [excelData]);

  const handleFileChange = async (info) => {
    setLoading(true);

    if (info.file.status === 'done') {
      try {
        const file = info.file.originFileObj;

        /*const workbook = new ExcelJS.Workbook();

        await workbook.xlsx.load(file);
        const worksheet = workbook.worksheets[0];
        const rows = [];
        const columnNames = ['linkUrl', 'keyword'];
        worksheet.eachRow((row, rowNumber) => {
          const rowData = {};
          columnNames.forEach((columnName, colNumber) => {
            rowData[columnName] = row.getCell(colNumber + 1).text;
          });
          rows.push(rowData);
        });
        const dataExcel = rows.filter((link) => link.linkUrl.trim() !== '');

        const params = {
          suggestId: suggestId,
          linkUrl: dataExcel?.slice(1).map((item) => item.linkUrl),
        };
        const res = await createPostLink(params);
        if (res?.statusCode === 200) {
          dispatch(listLinkExcel(res?.data));
          message.success('Thêm link mới thành công!');
        } else {
          message.error('Thêm link mới thất bại, link không đúng định dạng :(');
        }*/

        const formData = new FormData();
        formData.append('file', file);

        // define xhr
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${process.env.REACT_APP_API_URL}api/link/upload/${suggestId}`);
        xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`);
        xhr.send(formData);
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            console.log(xhr.status)
            if (xhr.status === 200 || xhr.status === 201) {
              const res = JSON.parse(xhr.responseText);
              dispatch(listLinkExcel(res));
              message.success('Thêm link mới thành công!');
            } else {
              message.error('Thêm link mới thất bại, link không đúng định dạng :(');
            }
          }
        }

      } catch (error) {
        console.log(error);
        message.error(`${info.file.name} file upload failed.`);
      }
    } else if (info.file.status === 'error') {
      message.error('Error uploading file.');
    }
    setLoading(false);
  };

  const customRequest = ({ file, onSuccess, onError }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const exportToExcel = async () => {
    // Tạo một workbook mới
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');

    // Thêm dữ liệu mẫu
    worksheet.columns = [
      { header: 'URL', key: 'col1', width: 50 },
      { header: 'KEYWORD', key: 'col2', width: 50 }
    ];

    worksheet.addRow(['https://link.net', 'keyword1, keyword2']);
    worksheet.addRow(['https://link.com', 'keyword1, keyword2']);
    // Tạo tập tin Excel
    const buffer = await workbook.xlsx.writeBuffer();

    // Tạo blob từ buffer
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Tạo URL cho blob
    const url = window.URL.createObjectURL(blob);

    // Tạo một link để tải xuống
    const a = document.createElement('a');
    a.href = url;
    a.download = 'file-mau.xlsx';
    document.body.appendChild(a);
    a.click();

    // Xóa link sau khi đã tải xuống
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  return (
    <Space>
      <Upload
        customRequest={customRequest}
        onChange={handleFileChange}
        showUploadList={false}
      >
        <Button
          loading={loading}
          disabled={loading === true}
          icon={<UploadOutlined />}
          style={hovered === true ? style : null}
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
        >
          Upload Excel
        </Button>
      </Upload>
      <Button
        icon={<DownloadOutlined />}
        onClick={exportToExcel}
        className='bg-[#16a34a] text-white border-[#16a34a] '
      >
        Xuất File Mẫu
      </Button>
    </Space>
  );
};

export default ImportExcel;
