import { Button, Flex, Input, Popconfirm, Select, Space, message } from 'antd';
import React from 'react';
import {
  SearchOutlined,
  ExportOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  SyncOutlined,
  CheckOutlined,
  IssuesCloseOutlined,
  CheckSquareOutlined,
  Loading3QuartersOutlined
} from '@ant-design/icons';
import { handleExport } from '../../common/ExportExcel';
import { useState } from 'react';
import {
  getLink,
  postConfigCheckIndex,
  postConfigIndex,
  postConfigLink,
} from '../../../services/api';
import ImportExcel from '../../common/ImportExcel';
import { useDispatch } from 'react-redux';
import { filter, setFilterValue } from '../../../redux/reducer';

const headerRowFile = [
  'STT',
  'URL',
  'Người tạo',
  'Ngày tạo',
  'Ngày check',
  'Is follow',
  'Is index',
  'Indexed',
  'Trạng thái',
];

export const ManageLink = (props) => {
  const {
    getValueSearch,
    oppenModalLink,
    search,
    handleDeleteSelectRows,
    selectedRowKeys,
    dataLink,
    detailSuggest,
    suggestId,
    socket,
    handleCheckLinkSocket,
    fetchDataLink,
    loading,
    showModal,
  } = props;
  const [open, setOpen] = useState(false);
  const [openCheckIndex, setOpenCheckIndex] = useState(false);
  const [openIndex, setOpenIndex] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isExport, setIsExport] = useState(false);

  const dispatch = useDispatch();

  const showPopconfirm = () => {
    setOpen(true);
    setOpenCheckIndex(false);
    setOpenIndex(false);
  };

  const showPopCheckIndex = () => {
    setOpen(false);
    setOpenCheckIndex(true);
    setOpenIndex(false);
  };

  const showPopIndex = () => {
    setOpen(false);
    setOpenCheckIndex(false);
    setOpenIndex(true);
  };

  const handleCancel = () => {
    if (open === true) setOpen(false);
    if (openCheckIndex === true) setOpenCheckIndex(false);
    if (openIndex === true) setOpenIndex(false);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await postConfigLink({ suggestId: suggestId });
    if (res.status === true) {
      message.success('Check link thành công!');
    } else {
      message.error(res.message);
    }

    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 3000);
  };

  const handleCheckIndex = async () => {
    setConfirmLoading(true);
    const res = await postConfigCheckIndex({ suggestId: suggestId });
    if (res.status === true) {
      message.success('Check index thành công!');
    } else {
      message.error(res.message);
    }
    setTimeout(() => {
      setOpenCheckIndex(false);
      setConfirmLoading(false);

      fetchDataLink();
    }, 2000);
  };

  const handleIndex = async () => {
    setConfirmLoading(true);
    const res = await postConfigIndex({ suggestId: suggestId });
    if (res.status === false) {
      message.error(res.message);
    } else {
      message.success('Ép index thành công!');
    }
    setTimeout(() => {
      setOpenIndex(false);
      setConfirmLoading(false);
    }, 3000);
  };

  const handleChange = (value, name) => {
    dispatch(setFilterValue({ name, value }));
  };

  const handleExportExcel = (suggestId) => {
    setIsExport(true);
    getLink({suggestId: suggestId, limit: 99999, page: 1})
      .then(async (res) => {
        if (res?.data?.items?.length) {
          await handleExport(
            res.data.items,
            headerRowFile,
            `linkList-${detailSuggest?.websiteId?.domain}`
          );
        }
      })
      .catch((err) => {
        message.error('Có lỗi xảy ra!');
      })
      .finally(() => {
        setIsExport(false);
      });
  }

  return (
    <>
      <p className='text-2xl font-semibold py-4'>Danh sách Link</p>
      <Flex gap={'small'} className='mb-3' wrap='wrap'>
        <Space.Compact style={{ width: 300 }}>
          <Input
            addonBefore={<SearchOutlined />}
            placeholder='Tìm kiếm URL...'
            onChange={(e) => getValueSearch(e.target.value)}
            allowClear
            value={search}
          />
        </Space.Compact>

        <Select
          placeholder='Lọc isFollow'
          style={{
            width: 120,
          }}
          allowClear
          onChange={(value) => handleChange(value, 'isFollow')}
          options={[
            {
              value: 'follow',
              label: 'Follow',
            },
            {
              value: 'waiting',
              label: 'Waiting',
            },
            {
              value: 'nofollow',
              label: 'Nofollow',
            },
          ]}
        />
        <Select
          allowClear
          placeholder='Lọc isIndex'
          style={{
            width: 120,
          }}
          onChange={(value) => handleChange(value, 'isIndex')}
          options={[
            {
              value: 'index',
              label: 'Index',
            },
            {
              value: 'waiting',
              label: 'Waiting',
            },
            {
              value: 'noindex',
              label: 'Noindex',
            },
            {
              value: 'fail',
              label: 'Fail',
            },
          ]}
        />
        <Select
          allowClear
          placeholder='Trạng thái check link'
          style={{
            width: 120,
          }}
          onChange={(value) => handleChange(value, 'status')}
          options={[
            {
              value: 'success',
              label: 'Link còn',
            },
            {
              value: 'cancel',
              label: 'Link mất',
            },
            {
              value: 'waiting',
              label: 'Đang xử lý',
            },
          ]}
        />
        <Select
          allowClear
          placeholder='Lọc chỉ mục'
          style={{
            width: 120,
          }}
          onChange={(value) => handleChange(value, 'indexed')}
          options={[
            {
              value: 'index',
              label: 'Index',
            },
            {
              value: 'noindex',
              label: 'No index',
            },
            {
              value: 'waiting',
              label: 'Chưa xử lý',
            },
            {
              value: 'fail',
              label: 'Thất bại',
            },
            {
              value: 'processing',
              label: 'Đã gửi yêu cầu',
            },
          ]}
        />
      </Flex>
      <div className='flex mb-3 gap-2'>
        <Button
          type='primary'
          className='add-btn2'
          onClick={() => oppenModalLink()}
          icon={<PlusOutlined />}
        >
          Thêm Link mới
        </Button>

        <ImportExcel suggestId={suggestId} />

        <Button
          onClick={() => handleExportExcel(suggestId)}
          className='text-[#16a34a] border-[#16a34a] hover:bg-[#16a34a] hover:text-white'
          disabled={dataLink?.length === 0 || isExport}
          icon={isExport ? <Loading3QuartersOutlined spin /> : <ExportOutlined />}
        >
          Xuất Excel
        </Button>

        <Button
          type='primary'
          onClick={showModal}
          className='btn-modal'
          icon={<CheckCircleOutlined />}
        >
          Kiểm tra
        </Button>
        <Button
          onClick={handleDeleteSelectRows}
          type='primary'
          danger
          disabled={selectedRowKeys.length === 0}
          icon={<DeleteOutlined />}
        >
          Xóa Tất Cả
        </Button>

        <Button
          onClick={fetchDataLink}
          type='primary'
          className='add-reset flex items-center bg-slate-600 text-white'
          style={{ margin: 0 }}
          disabled={loading}
          icon={<SyncOutlined spin={loading} />}
        >
          Reload
        </Button>
      </div>
      <Space className='mb-3'>
        <Popconfirm
          title='Thông tin config'
          description={
            <>
              Bạn muốn check các URL của {detailSuggest?.websiteId?.domain} ?
              <br />
              Check Link giúp kiểm tra tình trạng các link có
              <br /> website của mình không
            </>
          }
          okText='Có '
          cancelText='Không'
          open={open}
          onConfirm={handleOk}
          okButtonProps={{
            loading: confirmLoading,
            className: 'bg-blue-500',
          }}
          onCancel={handleCancel}
          cancelButtonProps={{ className: 'cancelButtonProps' }}
        >
          <Button
            type='primary'
            className='bg-blue-500'
            disabled={dataLink?.length === 0}
            onClick={showPopconfirm}
            icon={<CheckOutlined />}
          >
            Check Link
          </Button>
        </Popconfirm>
        <Popconfirm
          title='Thông tin config'
          description={
            <>
              Check Index giúp kiểm tra các Link đã Index trên google
              <br /> hay chưa.
            </>
          }
          okText='Có '
          cancelText='Không'
          open={openCheckIndex}
          onConfirm={handleCheckIndex}
          okButtonProps={{
            loading: confirmLoading,
            className: 'bg-blue-500',
          }}
          onCancel={handleCancel}
          cancelButtonProps={{ className: 'cancelButtonProps' }}
        >
          <Button
            onClick={showPopCheckIndex}
            type='primary'
            danger
            disabled={dataLink?.length === 0}
            icon={<IssuesCloseOutlined />}
          >
            Check Index
          </Button>
        </Popconfirm>
        <Popconfirm
          title='Thông tin config'
          description={
            <>
              Index giúp ép index các link chưa index trên google.
              <br /> Với 1 link index thành công sẽ tốn 400đ.
            </>
          }
          okText='Có '
          cancelText='Không'
          open={openIndex}
          onConfirm={handleIndex}
          okButtonProps={{
            loading: confirmLoading,
            className: 'bg-blue-500',
          }}
          onCancel={handleCancel}
          cancelButtonProps={{ className: 'cancelButtonProps' }}
        >
          <Button
            onClick={showPopIndex}
            danger
            disabled={dataLink?.length === 0}
            icon={<CheckSquareOutlined />}
          >
            Index
          </Button>
        </Popconfirm>
      </Space>
    </>
  );
};
