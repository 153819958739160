export const CONFIG_INDEX = {
  SYNBYTE: 'sinbyte',
  ONEHPING: '1hping'
}

export const INDEX_LINK = {
  INDEX: 'index',
  WAITING: 'waiting',
  NOINDEX: 'noindex',
  PROCESSING: 'processing',
  FAIL: 'fail',
  PROCESSING_CHECK_INDEX: 'processing_check_index',
}