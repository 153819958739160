import { Button, Divider, Form, Input, Modal, Select, Space } from 'antd';
import React from 'react';

const ModalSinbyte = (props) => {
  const {
    title,
    show,
    handleCloseModal,
    form,
    submitModal,
    listLead,
    selectValue,
    getIdLeader,
    userId,
  } = props;
  const { Option } = Select;

  return (
    <Modal
      title={title}
      open={show}
      onCancel={() => handleCloseModal()}
      footer={null}
    >
      <Divider />
      <Form
        form={form}
        name='basic'
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={submitModal}
        autoComplete='on'
      >
        <Form.Item
          label='Tên nhóm'
          name='name'
          rules={[
            {
              required: true,
              message: 'Không được để trống!',
            },
          ]}
        >
          <Input allowClear placeholder='Team 11' />
        </Form.Item>

        <Form.Item
          label='Api Key Sinbyte'
          name='apiKey'
          rules={[
            {
              required: true,
              message: 'Nhập Api Key Sinbyte!',
            },
          ]}
        >
          <Input
            allowClear
            placeholder='pp74xezyqu6gxjn1bzj0udygiompcb0titotlwu7'
          />
        </Form.Item>

        {userId !== 'leader' && (
          <Form.Item
            className='relative z-0 w-full mb-6 group'
            label='Leader'
            name='leaderId'
            rules={[
              {
                required: true,
                message: 'Không được để trống!',
              },
            ]}
          >
            <Select
              placeholder='Chọn Leader'
              onChange={(value, option) => selectValue(value, option)}
              value={getIdLeader}
            >
              {listLead?.map((item) => (
                <Option value={item._id} key={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {/*  note sinbyte */}
        <p className='text-red-600  '>
          (*) Để lấy ApiKey vui lòng đăng nhập vào trang
          <br />
          <a
            className='text-blue-600'
            href='https://app.sinbyte.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://app.sinbyte.com/
          </a>{' '}
          và truy cập tới đường dẫn{' '}
          <a
            className='text-blue-600'
            href='https://app.sinbyte.com/quick-indexing/'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://app.sinbyte.com/quick-indexing/
          </a>{' '}
          để lấy apiKey.
        </p>
        <p className='text-red-600'>
          (*) Mỗi tài khoản Team Leader chỉ tạo được 1 Api Key.
        </p>
        <div className='text-right mt-5'>
          <Space>
            <Button type='primary' danger onClick={handleCloseModal}>
              Hủy
            </Button>
            <Button htmlType='submit' type='primary' className='btn-modal'>
              Xác nhận
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalSinbyte;
