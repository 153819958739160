import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'userSlice',
  initialState: {
    idView: '',
  },
  reducers: {
    getIdValue: (state, action) => {
      state.idView = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { getIdValue } = userSlice.actions;

export default userSlice.reducer;
