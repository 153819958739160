import {Button, Form, Input, Modal, Space} from 'antd';
import React from 'react';
import TextArea from 'antd/es/input/TextArea';
import {useSelector} from 'react-redux';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';

const ModalLink = (props) => {
  const {showModalLink, formAddLink, handleCloseModal, onFinish, title} =
    props;
  /*const {idLink} = useSelector((state) => state.filterLinkSlice);*/

  return (
    <>
      {/* add link */}
      <Modal
        title={title ? title : 'Thêm Link Mới'}
        open={showModalLink}
        onCancel={() => handleCloseModal()}
        footer={null}
      >
        <Form
          form={formAddLink}
          onFinish={onFinish}
          style={{
            maxWidth: 600,
          }}
          autoComplete='on'
          layout='vertical'
        >
          <p className='text-red-600'>
            Vui lòng kiểm tra kĩ thông tin keyword để hệ thống kiểm tra chính
            xác
          </p>
          <Form.Item
            name='linkUrl'
            label={'Link URL, không nhập trùng link'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập URL',
              },
            ]}
          >
            <TextArea
              rows={8}
              placeholder='vd: web.com
                www.web.net'
            />
          </Form.Item>
          <Form.List
            name='keywords'
          >
            {(fields, {add, remove}, {errors}) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? 'Từ khóa' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Vui lòng nhập từ khóa.',
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder='Nhập từ khóa'
                        allowClear
                        style={{
                          width: '90%',
                        }}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className='ml-3'
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type='dashed'
                    onClick={() => add()}
                    style={{
                      width: '60%',
                    }}
                    icon={<PlusOutlined/>}
                  >
                    Thêm từ khóa
                  </Button>

                  <Form.ErrorList errors={errors}/>
                </Form.Item>
              </>
            )}
          </Form.List>

          <div className='text-right mt-10'>
            <Space>
              <Button type='primary' danger onClick={handleCloseModal}>
                Hủy
              </Button>
              <Button htmlType='submit' type='primary' className='btn-modal'>
                Xác nhận
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalLink;
