import { Button, Flex, Input, Popconfirm, Select, Skeleton, Space } from 'antd';
import React, { useState } from 'react';
import DataTable from '../../common/DataTable';
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

const Content = () => {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [total, setTotal] = useState('');

  const dataSource = [];

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'name',
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'updated_at',
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (text, data, index) => (
        <Space size='middle' className='gap-2 items-center '>
          <button
            //   onClick={() => openModal(data.key)}
            className='bg-[#fd9900] action-button'
          >
            <EditOutlined />
          </button>
          <Popconfirm
            cancelButtonProps={{ className: 'bg-red-500 text-white btn-del' }}
            okButtonProps={{
              className: 'bg-blue-600 text-white',
            }}
            title='Xóa Sinbyte'
            description='Bạn muốn xóa key này?'
            //   onConfirm={() => handleDelete(data)}
            okText='Yes'
            cancelText='No'
            okType=''
          >
            <button className='bg-red-600 action-button'>
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Flex gap={'small'} wrap='wrap' className='mt-6'>
        <Space.Compact className='mt-[-20px] mb-[20px] w-[300px]'>
          <Input
            addonBefore={<SearchOutlined />}
            placeholder='Tìm kiếm tên và email...'
            allowClear
            // onChange={debouncedFunction}
          />
        </Space.Compact>
        <Select
          defaultValue=''
          className='w-[240px] mb-6 add-user-btn'
          // onChange={handleChange}
        >
          <Option value=''>Tất cả</Option>
          <Option value='admin'>Admin</Option>
          <Option value='leader'>Leader</Option>
          <Option value='user'>User</Option>
        </Select>
      </Flex>
      <Flex gap={'small'} wrap='wrap' className='mt-3'>
        <Button
          type='primary'
          className='add-btn'
          //   onClick={() => {
          //     setShow(true);
          //     setTitle('Thêm Nhân Viên Mới');
          //   }}
        >
          Tạo mới
        </Button>
      </Flex>
      <Skeleton loading={loading}>
        <DataTable
          columns={columns}
          listData={dataSource}
          pageSize={pagination.limit}
          indexPage={pagination.page}
          totalPage={total}
          // onPageChange={paginate}
          scroll={{ x: 1500 }}
        />
      </Skeleton>
    </>
  );
};

export default Content;
