import React from "react";
import { Modal, Divider } from "antd";
import moment from "moment";

export default function ModalView({ isModalOpen, setIsModalOpen, data }) {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const formattedDate = data?.website?.created_at
    ? moment(data.website.created_at).format("DD/MM/YYYY HH:mm")
    : "";

  return (
    <div>
      <Modal
        footer={false}
        title="Thông tin"
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Divider />
        <div className="modal-info">
          <span className="w-[40%]">Domain:</span>
          <span className="w-[60%]">{data?.website?.domain}</span>
        </div>
        <div className="modal-info">
          <span className="w-[40%]">Mô tả:</span>
          <span className="w-[60%]">
            {data?.website?.description
              ? data?.website?.description
              : "Không có mô tả!"}
          </span>
        </div>
        <div className="modal-info">
          <span className="w-[40%]">Leader Name:</span>
          <span className="w-[60%]">{data?.website?.leaderId?.name}</span>
        </div>
        <div className="modal-info">
          <span className="w-[40%]">Leader Email:</span>
          <span className="w-[60%]">{data?.website?.leaderId?.email}</span>
        </div>

        {data?.members &&
          data?.website?.leaderId?.name &&
          data?.members?.map((item, index) => (
            <div key={index} className="modal-info">
              <span className="w-[40%]">Member:</span>
              <span className="w-[60%]">{`${item?.name} - ${item?.email}`}</span>
            </div>
          ))}

        <div className="modal-info">
          <span className="w-[40%]">Ngày tạo:</span>
          <span className="w-[60%]">{formattedDate}</span>
        </div>
      </Modal>
    </div>
  );
}
