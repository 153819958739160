import { APIClient } from '../utils/axiosCustomize';
const axios = new APIClient();
const BASE_URL = 'api/';

function buildApiEndpoint(resource, params) {
  // check if params is undefined or null
  if (!params) {
    return `${BASE_URL}${resource}`;
  }
  const endpoint = Object.entries(params)
    .filter(([key, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const url = endpoint
    ? `${BASE_URL}${resource}?${endpoint}`
    : `${BASE_URL}${resource}`;
  return url;
}

//LOGIN

export function callLogin(data) {
  return axios.post('api/auth/login', data);
}

export function getAccountInfo() {
  return axios.get(`/api/auth/me`);
}

export function createNewPassword(data) {
  return axios.post(`/api/auth/change-password`, data);
}

// user

export function getUsers(params) {
  const endpoint = buildApiEndpoint('user', params);
  return axios.get(endpoint);
}

export function getUserById(id) {
  return axios.get(`${BASE_URL}user/${id}`);
}

export function getUsersByLeaderId(leaderId) {
  const params = { leaderId };
  const endpoint = buildApiEndpoint('user', params);
  return axios.get(endpoint);
}

export function putEditUser(id, data) {
  return axios.put(`${BASE_URL}user/${id}`, data);
}

export function postCreteNewUser(data) {
  return axios.post(`${BASE_URL}user`, data);
}

export function deleteUser(id) {
  return axios.delete(`${BASE_URL}user/${id}`);
}

export function blockUser(id) {
  return axios.put(`${BASE_URL}user/block/${id}`);
}

export function unBlockUser(id) {
  return axios.put(`${BASE_URL}user/unlock/${id}`);
}

//Hàm lấy ra danh sách user dùng chung, chỉ thay đổi object truyền vào 24/11
export function getAllUsers(data) {
  return axios.get(`/api/user`, data);
}

// TELEGRAM
export function getTele(data) {
  const endpoint = buildApiEndpoint('telegram');
  return axios.get(endpoint, data);
}

export function getTeleById(id) {
  return axios.get(`${BASE_URL}telegram/${id}`);
}

export function postCreteTele(data) {
  return axios.post(`${BASE_URL}telegram`, data);
}

export function deleteTele(id) {
  return axios.delete(`${BASE_URL}telegram/${id}`);
}

export function putUpdateTele(id, params) {
  return axios.put(`${BASE_URL}telegram/${id}`, params);
}

// SINBYTE
export function getSinbyte() {
  const endpoint = buildApiEndpoint('sinbyte');
  return axios.get(endpoint);
}

export function getSinbyteById(id) {
  return axios.get(`${BASE_URL}sinbyte/${id}`);
}

export function postSinbyte(data) {
  return axios.post(`${BASE_URL}sinbyte`, data);
}

export function deleteSinbyte(id) {
  return axios.delete(`${BASE_URL}sinbyte/${id}`);
}

export function putSinbyte(id, data) {
  return axios.put(`${BASE_URL}sinbyte/${id}`, data);
}

//SUGGEST

export function getSuggestPaging(data) {
  return axios.get(`/api/suggest`, data);
}

export function getSuggest(id, data) {
  return axios.get(`/api/suggest/${id}`, data);
}

export function getSuggestDetail(id) {
  return axios.get(`/api/suggest/detail/${id}`);
}

export function createSuggest(data) {
  return axios.post('/api/suggest', data);
}

export function deleteSuggese(id) {
  return axios.delete(`/api/suggest/${id}`);
}

export function updateSuggest(data) {
  return axios.put(`/api/suggest/${data.id}`, data);
}

export function deleteManySuggest(data) {
  return axios.delete(`/api/suggest/delete-many`, {
    data
  });
}

// WEBSITE

export function getWebsite(data) {
  return axios.get('/api/website', data);
}

export function createWebsite(data) {
  return axios.post('/api/website', data);
}

export function DeleteWebsite(data) {
  return axios.delete('/api/website', data);
}

export function updateWebSite(data) {
  return axios.put(`/api/website/${data.id}`, data);
}

export function getWebsiteById(id) {
  return axios.get(`/api/website/${id}`);
}

export function DeleteWebsiteById(id) {
  return axios.delete(`/api/website/${id}`);
}

//HISTORY

export function getHistory(data) {
  return axios.get('/api/history', data);
}

export function getHistoryById(id) {
  return axios.get(`/api/history/${id}`);
}

//NOTIFY

export function getNotify(data) {
  return axios.get('/api/notify', data);
}

export function getNotifyById(id) {
  return axios.get(`/api/notify/${id}`);
}

export function DeleteNotiFyById(id) {
  return axios.delete(`/api/notify/${id}`);
}

// link
export function getLink(params) {
  return axios.get('/api/link', params);
}

export function getDetailLink(id) {
  return axios.get(`/api/link/${id}`);
}

export function createPostLink(data) {
  return axios.post(`${BASE_URL}link`, data);
}

export function putLink(id, data) {
  return axios.put(`${BASE_URL}link/${id}`, data);
}

export function deleteLink(id) {
  return axios.delete(`${BASE_URL}link/${id}`);
}

export function deleteLinkSelect(string) {
  return axios.delete(`${BASE_URL}link`, string);
}

// config
export function postConfigLink(suggestId) {
  return axios.post(`${BASE_URL}config/check-link`, suggestId);
}

export function postConfigCheckIndex(suggestId) {
  return axios.post(`${BASE_URL}config/check-link-index`, suggestId);
}

export function postConfigIndex(suggestId) {
  return axios.post(`${BASE_URL}config/indexed`, suggestId);
}

// Config index
export function getConfigIndex(params) {
  return axios.get('/api/config-index', params);
}

export function deleteConfigIndex(id) {
  return axios.delete(`/api/config-index/${id}`);
}

export function createConfigIndex(data) {
  return axios.post('/api/config-index', data);
}

export function updateConfigIndex(id, data) {
  return axios.put(`/api/config-index/${id}`, data);
}
