import React, {useEffect, useState} from "react";
import {createConfigIndex, getAllUsers, updateConfigIndex} from "../../../services/api";
import {Button, Divider, Form, Input, Select, Space, Modal, message} from "antd";
import {CONFIG_INDEX} from "../../common/Enum";

export default function ModalConfigIndex(props) {

  const {
    title,
    show,
    handleCloseModal,
    form,
    submitModal,
    dataEdit,
  } = props;

  const configIndexOptions = [
    {label: 'Sinbyte', value: 'sinbyte'},
    {label: '1hping', value: '1hping'},
  ]

  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [leaderId, setLeaderId] = useState();
  const [leader, setLeader] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [configType, setConfigType] = useState(null);

  useEffect(() => {
    if (show) {
      let data = localStorage.getItem('data');
      data = JSON.parse(data);
      const role = localStorage.getItem('role');
      if (role === 'leader') {
        setLeaderId(data?.user._id);
      }

      if (role === 'admin') {
        setIsAdmin(true);
        getAllLeader();
      }
    }
  }, [show]);

  useEffect(() => {
    if(dataEdit) {
      setConfigType(dataEdit.code)
      form.setFieldsValue({
        code: dataEdit.code,
        name: dataEdit.name,
        apiKey: dataEdit.apiKey,
        username: dataEdit.username,
        password: dataEdit.password,
        leaderId: dataEdit.leaderId,
        key: dataEdit.key,
      })
    }
  }, [dataEdit])

  const getAllLeader = () => {
    setIsLoading(true)
    try {
      if (localStorage.getItem('role') !== 'user') {
        getAllUsers({role: 'leader', limit: 100000}).then(res => {
          if (res?.data?.items?.length > 0) {
            setLeader(res.data.items);
          }
        })
      }
    } finally {
      setIsLoading(false)
    }
  };

  const handleChangeConfigType = (value) => {
    setConfigType(value);
  };

  const handleChangeLeader = (value) => {
  };

  const handleFormSubmit = async (values) => {
    try {
      setIsSubmitForm(true)
      let response = null;

      if(dataEdit) {
        response = await updateConfigIndex(dataEdit.key, values);
      } else {
        response = await createConfigIndex(values);
      }

      if (!response?.status) {
        message.error(response?.message ?? 'Đã xảy ra lỗi, vui lòng thử lại sau!');
      } else {
        message.success(dataEdit ? 'Tạo cấu hình thành công!': 'Sửa cấu hình thành công!');
        submitModal();
      }
    } catch (e) {
      message.error('Đã xảy ra lỗi, vui lòng thử lại sau!');
    } finally {
      setIsSubmitForm(false)
    }
  }

  return (
    <div>
      <Modal
        title={title}
        open={show}
        onCancel={() => handleCloseModal()}
        footer={null}
      >
        <Divider/>
        {isLoading
          ?
          <div
            style={{
              minHeight: 300,
            }}
            className={'flex items-center justify-center'}
          >
            <svg
              aria-hidden="true"
              className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
          :
          <>
            <Form
              form={form}
              name='basic'
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={handleFormSubmit}
              autoComplete='on'
            >

              <Form.Item
                label='Loại cấu hình'
                name='code'
                rules={[
                  {
                    required: true,
                    message: 'Chọn loại cấu hình',
                  },
                ]}
              >
                <Select placeholder='Loại cấu hình' onChange={handleChangeConfigType}>
                  {configIndexOptions.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label='Tên cấu hình'
                name='name'
                rules={[
                  {
                    required: true,
                    message: 'Nhập tên cấu hình',
                  },
                ]}
              >
                <Input allowClear placeholder='Nhập tên cấu hình'/>
              </Form.Item>

              {configType === CONFIG_INDEX.SYNBYTE && (
                <Form.Item
                  label='API Key'
                  name='apiKey'
                  rules={[
                    {
                      required: true,
                      message: 'Api Key Sinbyte',
                    },
                  ]}
                >
                  <Input allowClear placeholder='Api Key Sinbyte'/>
                </Form.Item>
              )}

              {configType === CONFIG_INDEX.ONEHPING && (
                <>
                  <Form.Item
                    label='Email'
                    name='username'
                    rules={[
                      {
                        required: true,
                        message: 'Email không được để trống',
                      },
                    ]}
                  >
                    <Input allowClear placeholder='Nhập email'/>
                  </Form.Item>

                  <Form.Item
                    label='Mật khẩu'
                    name='password'
                    rules={[
                      {
                        required: !dataEdit,
                        message: 'Mật khẩu không được để trống',
                      },
                    ]}
                  >
                    <Input allowClear placeholder='Nhập mật khẩu'/>
                  </Form.Item>
                </>
              )}

              {isAdmin && (
                <Form.Item
                  label='Leader'
                  name='leaderId'
                  rules={[
                    {
                      required: true,
                      message: 'Chọn leader!',
                    },
                  ]}
                >
                  <Select placeholder='Chọn leader' onChange={handleChangeLeader}>
                    {leader?.length > 0 &&
                      leader?.map((item) => {
                        return (
                          <Select.Option key={item._id} value={item._id}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              )}

              {configType === CONFIG_INDEX.SYNBYTE && (
                <>
                  <p className='text-red-600'>
                    (*) Để lấy ApiKey vui lòng đăng nhập vào trang
                    <br/>
                    <a
                      className='text-blue-600'
                      href='https://app.sinbyte.com/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      https://app.sinbyte.com/
                    </a>{' '}
                    và truy cập tới đường dẫn{' '}
                    <a
                      className='text-blue-600'
                      href='https://app.sinbyte.com/quick-indexing/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      https://app.sinbyte.com/quick-indexing/
                    </a>{' '}
                    để lấy apiKey.
                  </p>
                  <p className='text-red-600'>
                    (*) Mỗi tài khoản Team Leader chỉ tạo được 1 Api Key.
                  </p>
                </>
              )}

              {configType === CONFIG_INDEX.ONEHPING && (
                <>
                  <p className='text-red-600'>
                    (*) Vui lòng truy cập vào trang{' '}
                    <a
                      className='text-blue-600'
                      href='https://app.1hping.com/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      https://app.1hping.com/
                    </a>{' '}
                    để đăng ký tài khoản. Sau khi đăng ký và đăng nhập thành công, vui lòng nhập thông tin tài khoản vào
                    form. <br/>
                    Lưu ý: Vui lòng kiểm tra kĩ thông tin tài khoản để hệ thống kiểm tra chính xác!
                  </p>
                  <p className='text-red-600'>
                    (*) Mỗi tài khoản Team Leader chỉ tạo được 1 tài khoản 1hping.
                  </p>
                </>
              )}

              <div className='text-right mt-10'>
                <Space>
                  <Button type='primary' danger onClick={handleCloseModal}>
                    Hủy
                  </Button>
                  <Button htmlType='submit' type='primary' className='btn-modal' loading={isSubmitForm}>
                    Xác nhận
                  </Button>
                </Space>
              </div>
            </Form>
          </>
        }
      </Modal>
    </div>
  )
}