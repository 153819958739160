// check url tele
export function isValidUrl(url) {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

export function formatVND(amount) {
  if (isNaN(amount)) {
    return 'Không phải là số';
  }

  const formattedAmount = amount.toLocaleString('vi-VN', {
    style: 'currency',
    currency: 'VND',
  });

  return formattedAmount;
}

export const validateNumberRange = (rule, value, callback) => {
  const numberValue = parseInt(value, 10);

  if (isNaN(numberValue)) {
    callback('Vui lòng nhập một số!');
  } else if (numberValue < 15 || numberValue > 60) {
    callback('Chọn thời gian từ 15 đến 60 giây!');
  } else {
    callback(); // Giá trị hợp lệ
  }
};

export const debounce = (func, delay) => {
  let timerId;
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      func.apply(this, args);
      timerId = null;
    }, delay);
  };
};
