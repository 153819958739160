import React, { useEffect, useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LockOutlined,
  RollbackOutlined,
  QuestionCircleOutlined,
  DownOutlined,
} from '@ant-design/icons';
import {
  Layout,
  Button,
  theme,
  Avatar,
  Dropdown,
  message,
  Form,
  Space,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { getAccountInfo, createNewPassword, getNotify } from '../services/api';
import ModalViewInfo from './Modal/ModalViewInfo';
import ModalChangePass from './Modal/ModalChangePass';
import img from '../assets/img3.jpg';

const { Header } = Layout;

const HeaderMain = ({ collapsed, setCollapsed, userInfo }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isModalUser, setIsModalUser] = useState(false);
  const [isModalChangePass, setIsModalChangePass] = useState(false);
  const [noti, setListNoti] = useState();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    handleInfoUser();
    // fetchDataNoti();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const handleInfoUser = async () => {
    // const res = await getAccountInfo();
    // setUserInfo(res?.data);
    // if (res === undefined) {
    //   navigate('/login');
    // }
    // localStorage.setItem('role', res?.data?.roleId?.name);
  };

  const handleShowModel = () => {
    setIsModalUser(true);
  };

  const ShowChangePass = () => {
    setIsModalChangePass(true);
  };

  //Lấy danh sách thông báo để xử lý 29/11
  const fetchDataNoti = async () => {
    const res = await getNotify({ limit: 5 });
    setListNoti(res?.data?.items);
  };

  const onFinish = async (data) => {
    const res = await createNewPassword(data);
    if (res.statusCode === 200) {
      message.success('Đổi mật khẩu thành công!');
      setIsModalChangePass(false);
      form.resetFields();
    } else if (res.statusCode === 422) {
      message.error('Đổi mật khẩu thất bại!');
    }
  };

  const itemsSecondDropdown = [
    {
      label: <div onClick={handleShowModel}>Thông tin cá nhân</div>,
      key: '1',
      icon: <UserOutlined />,
    },
    {
      label: <div onClick={ShowChangePass}>Đổi mật khẩu</div>,
      key: '2',
      icon: <LockOutlined />,
    },
    {
      label: <div onClick={handleLogout}>Đăng xuất</div>,
      key: '3',
      icon: <RollbackOutlined />,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      // Kiểm tra chiều rộng của màn hình
      if (window.innerWidth <= 950) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };
    // event when width screen change
    window.addEventListener('resize', handleResize);
    // initial
    handleResize();
    // remove event when component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Chỉ chạy một lần khi component được mount

  return (
    <>
      <Header
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      >
        <div className='flex h-full justify-between'>
          <Button
            className='hidden md:block lg:block'
            type='text'
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 90,
              height: 64,
            }}
          />
          <div className='overflow-hidden w-full mr-3'>
            <div className='marquee'>
              <h1>CHÀO MỪNG BẠN ĐẾN VỚI OKTOOL CHECKLINK SERVICE 🎉</h1>
            </div>
          </div>
          <div className='flex items-center gap-3'>
            <Button
              style={{
                backgroundColor: '#ffa827',
                color: '#fff',
                border: 'none',
              }}
            >
              <a
                href='https://docs.google.com/document/d/15P3EJc6rwHOKCStsJwTyJ-3GewrNcHp6DyYfLvaqcmo/edit'
                target='_blank'
                className='w-full h-full block'
              >
                <QuestionCircleOutlined /> Hướng Dẫn
              </a>
            </Button>
            <Dropdown
              menu={{
                items: itemsSecondDropdown,
              }}
              trigger={['click']}
            >
              <a
                onClick={(e) => e.preventDefault()}
                className='role inline-block '
              >
                <div className='flex justify-between h-full items-center gap-3'>
                  <div className='h-full flex flex-col items-center text-base'>
                    <p className='font-semibold text-red-600'>
                      {userInfo?.roleId?.name}
                    </p>
                    <p className='overflow-hidden text-center'>
                      {userInfo?.name}
                    </p>
                  </div>
                  <div>
                    <Avatar
                      style={{ backgroundColor: 'rgb(255, 168, 39)', verticalAlign: 'middle' }}
                      size='large'
                    >
                      {userInfo?.name?.charAt(0).toUpperCase()}
                    </Avatar>
                  </div>
                </div>
              </a>
            </Dropdown>
          </div>
        </div>

        {/* <div className='mr-3'>
            <Button
              style={{
                backgroundColor: '#ffa827',
                color: '#fff',
                border: 'none',
              }}
            >
              <a
                href='https://docs.google.com/document/d/15P3EJc6rwHOKCStsJwTyJ-3GewrNcHp6DyYfLvaqcmo/edit'
                target='_blank'
                className='w-full h-full block'
              >
                <QuestionCircleOutlined /> Hướng Dẫn
              </a>
            </Button>
          </div>  

        <Dropdown
            menu={{
              items: itemsSecondDropdown,
            }}
            trigger={['click']}
          >
            <a onClick={(e) => e.preventDefault()} className='role  '>
              <div className='flex justify-between h-full items-center gap-3  '>
                <div className='h-full flex flex-col items-center text-base'>
                  <p className='font-semibold'>{userInfo?.roleId?.name}</p>
                  <p className='overflow-hidden text-center'>
                    {userInfo?.name}
                  </p>
                </div>
                <div>
                  <Avatar
                    size='large'
                    src={img}
                    // icon={<UserOutlined />}
                  />
                </div>
              </div>
            </a>
          </Dropdown>
        </div> */}
      </Header>

      <ModalViewInfo
        isModalUser={isModalUser}
        setIsModalUser={setIsModalUser}
        userInfo={userInfo}
      />
      <ModalChangePass
        isModalChangePass={isModalChangePass}
        setIsModalChangePass={setIsModalChangePass}
        onFinish={onFinish}
        form={form}
      />
    </>
  );
};

export default HeaderMain;
