import { Button, Divider, Form, Input, Modal, Select, Space } from "antd";
import React from "react";

export default function ModalTele(props) {
  const {
    title,
    show,
    handleCloseModal,
    form,
    submitModal,
    listLead,
    selectValue,
    getIdLeader,
    userId,
  } = props;
  const { Option } = Select;

  return (
    <Modal
      title={title}
      open={show}
      onCancel={() => handleCloseModal()}
      footer={null}
    >
      <Divider />
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={submitModal}
        autoComplete="off"
      >
        <Form.Item
          label="Tên nhóm"
          name="groupName"
          rules={[
            {
              required: true,
              message: "Không được để trống!",
            },
          ]}
        >
          <Input allowClear placeholder="Check link team 11" />
        </Form.Item>
        {userId === "leader" ? (
          ""
        ) : (
          <Form.Item
            className="relative z-0 w-full mb-6 group"
            label="Leader"
            name="leaderId"
            rules={[
              {
                required: true,
                message: "Không được để trống!",
              },
            ]}
          >
            <Select
              placeholder="Chọn Leader"
              onChange={(value, option) => selectValue(value, option)}
              value={getIdLeader}
            >
              {listLead?.map((item) => (
                <Option value={item._id} key={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label="Group thông báo"
          name="groupId"
          rules={[
            {
              required: true,
              message: "Nhập Group ID (Vd:-1002103804643)!",
            },
          ]}
        >
          <Input allowClear placeholder="Vd: -1002103804643" />
        </Form.Item>
        {/*  note gr tele */}
        <p className="text-red-600 ">
          (*) Tạo nhóm thông báo, sau đó add bot{" "}
          <a
            className="text-blue-600"
            href="https://t.me/CheckLinkServiceBot"
            target="_blank"
            rel="noopener noreferrer"
          >
            @CheckLinkServiceBot
          </a>{" "}
          vào nhóm và set quyền admin để nhận thông báo cho team.
        </p>
        <div className="text-right mt-2">
          <Space>
            <Button type="primary" danger onClick={handleCloseModal}>
              Hủy
            </Button>
            <Button htmlType="submit" type="primary" className="btn-modal">
              Xác nhận
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
}
