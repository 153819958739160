import { configureStore } from '@reduxjs/toolkit';
import filterLinkSlice from './reducer';
import userSlice from './userReducer';

export const store = configureStore({
  reducer: {
    filterLinkSlice: filterLinkSlice,
    userSlice: userSlice,
  },
});
